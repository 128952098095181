
type clinicIconType = {
    fill?:string,
    width?:string,
    height?:string,
    
}

const ClinicIcon = (props: clinicIconType) => {
    const{
        fill="#595D69",
        width="32",
         height="32"
    } = props
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M11.167 13.3335H13.8337V15.3335H11.167V13.3335Z" />
        <path d="M13.8337 16.6665H11.167V18.6665H13.8337V16.6665Z" />
        <path d="M11.167 20H13.8337V22H11.167V20Z" />
        <path d="M17.8337 13.3335H15.167V15.3335H17.8337V13.3335Z" />
        <path d="M15.167 16.6665H17.8337V18.6665H15.167V16.6665Z" />
        <path d="M17.8337 20H15.167V22H17.8337V20Z" />
        <path d="M19.167 13.3335H21.8337V15.3335H19.167V13.3335Z" />
        <path d="M21.8337 16.6665H19.167V18.6665H21.8337V16.6665Z" />
        <path d="M19.167 20H21.8337V22H19.167V20Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.1663 3.99984C21.1663 3.26346 20.5694 2.6665 19.833 2.6665H13.1663C12.4299 2.6665 11.833 3.26346 11.833 3.99984H7.16634V5.33317H8.49967V27.9998H6.49967C6.13149 27.9998 5.83301 28.2983 5.83301 28.6665C5.83301 29.0347 6.13149 29.3332 6.49967 29.3332H26.4997C26.8679 29.3332 27.1663 29.0347 27.1663 28.6665C27.1663 28.2983 26.8679 27.9998 26.4997 27.9998H24.4997V5.33317H25.833V3.99984H21.1663ZM19.833 11.9998C20.5694 11.9998 21.1663 11.4029 21.1663 10.6665V7.33317H23.1663V27.9998H21.1663V25.3332H21.833V23.9998H11.1663V25.3332H11.833V27.9998H9.83301V7.33317H11.833V10.6665C11.833 11.4029 12.4299 11.9998 13.1663 11.9998H19.833ZM15.833 27.9998H17.1663V25.3332H15.833V27.9998ZM23.1663 5.33317V5.99984H21.1663V5.33317H23.1663ZM9.83301 5.99984H11.833V5.33317H9.83301V5.99984ZM13.833 6.6665H15.833V4.6665H17.1663V6.6665H19.1663V7.99984H17.1663V9.99984H15.833V7.99984H13.833V6.6665Z" />
    </svg>

  )
}

export default ClinicIcon