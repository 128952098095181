import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';



import CustomButton from './Common/Button';



interface IAppProps {
  scrollToWaitlist:()=>void
}

const Navbar: React.FC<IAppProps> = (props) => {
  const{
    scrollToWaitlist
  }=props
  return (
    <>
      <Box sx={{width:'100%',flexGrow:1}}>
        <AppBar position='static' sx={{background:'#00165A',overflow:'hidden'}}>
          <Toolbar sx={{display:'flex',width:'100%',maxWidth:'1440px',justifySelf:'center',justifyContent:'space-between',alignSelf:'center'}}>
            {/* Logo */}
            <Box m={1} sx={{maxWidth:{xs:'110px',sm:'180px'}}} >
              <img src="https://res.cloudinary.com/dp3hktn91/image/upload/v1690219507/Medical%20Match/Logos/NavbarLogo_mzagco.png" style={{width:'100%',maxWidth:"180px"}} alt="MedicalMatch"/>
            </Box>
            {/* Navbar Buttons */}
            <Box sx={{display:'flex'}}>

              <a style={{textDecoration:'none'}} href={`mailto:support@medicalmatch.io`} target="_blank" rel="noreferrer">
              <CustomButton variant="text" backgroundColor='transparent' padding="8px" m={.5}>
                Contact Us
              </CustomButton>
              </a>
              <CustomButton m={0.5} onClick={scrollToWaitlist}>
                Join the waitlist
              </CustomButton>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Navbar;
