import { createTheme } from "@mui/material/styles";


const Theme = createTheme({
  components: {
    MuiButton: {
        styleOverrides: {
            root: ({ theme }) => ({
                '&.MuiButton-containedPrimary:hover': {
                    backgroundColor: theme.palette.primary.light,
                },
                '&.MuiButton-containedSecondary:hover': {
                    backgroundColor: theme.palette.secondary.light,
                },
            }),
        },
    },
},
  palette: {
    mode: "light",
    primary: {
      main: "#004DFE",
      dark:"#00165A",
    },
    secondary: {
      main: "#68D87A",
      light:"#CFFFD7"
    },
    text:{
        primary:"#000000",
        secondary:"#FFFFFF"
    }
  },
  typography: {
    fontFamily: "'Lato', sans-serif"
  },
 
});

export default Theme;
