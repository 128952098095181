import React, { ReactNode } from 'react';
import { Button as ButtonMUI,  Typography } from '@mui/material';
import { TypographyStyles } from '../Styles/TypographyStyles';
import Box from '@mui/material/Box';
type ButtonType = {
  backgroundColor?: string;
  padding?: string;
  textColor?: string;
  fontSize?: string;
  fontWeight?: string;
  borderRadius?:string;
  m?:number,
  variant?: "text" | "outlined" | "contained";
  children: ReactNode; // Change buttonText to children
  icon?:boolean,
  disabled?:boolean,
  type?:"button" | "submit" | "reset" | undefined,
  onClick?: ()=>void; // Callback function for onClick event
};

const CustomButton = (props: ButtonType) => {
  const {
    variant="contained",
    backgroundColor = 'primary.main',
    padding = '8px 32px 8px 32px',
    textColor = '#FFFFFF',
    m=1,
    disabled=false,
    type=undefined,
    fontWeight='700',
    borderRadius='60px',
    icon=false,
    children, // Change buttonText to children
    onClick,
  } = props;

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(); // Call the provided onClick callback function with the event
    }
  };
  

  return (
    <Box m={m}>
      {
      icon?
      <>
        <ButtonMUI type={type} sx={{width:'56px',height:'56px',padding:'12px',borderRadius:'999px',background:'rgba(255, 255, 255, 0.08)'}} onClick={handleOnClick}>
          {children}
        </ButtonMUI>
      </>
      :
      <>
    <ButtonMUI
      variant={variant}
      type={type}
      sx={{
        backgroundColor: backgroundColor,
        padding: padding,
        color: textColor,
        borderRadius:borderRadius,
        textTransform: 'none',
      }}
      onClick={handleOnClick} // Attach the onClick event handler
    >
        <Typography  sx={{...TypographyStyles.caption,fontWeight:fontWeight}}>
          {children} 
        </Typography>
    </ButtonMUI>
      </>
    }
    </Box>
  );
};

export default CustomButton;
