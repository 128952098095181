import { TypographyStyles } from './TypographyStyles';
export const FormStyles={
    inputSx:{
        color: 'black',
        '&.Mui-focused': {
            color: 'black',
        },
      },
    selectSx:{
        minWidth:{xs:'320px',md:'320px'},
        
        
        "&.MuiOutlinedInput-root ": {
            color:'rgba(0,0,0,1)',
            background:'rgba(242, 242, 243, 1)',
            borderRadius:'12px',
            "&:hover &.MuiOutlinedInput-root":{
                border:'1px solid'
            }
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
           borderColor:'rgba(242, 242, 243, 1)'
        },
    //   "&.MuiSelect-root:focus .MuiChip-root": {
    //     backgroundColor: "#f0f0f0",
    //     color: "black"
    //   }
    },
    toggleButtonGroupSx:{
        '& .MuiToggleButtonGroup-grouped': {
            
            marginY:'5px',
           marginX:{xs:'5px',sm:'15px'},
            minWidth:{xs:'155px',sm:'155px'},
            width:'100%',
            border: '1.5px solid',
            backgroundColor:'rgba(242, 242, 243, 1)',
            '&MuiToggleButtonGroup-grouped': {
                borderColor:'primary.main',
              },
            '&:focussed MuiToggleButtonGroup-grouped': {
                borderColor:'primary.main',
              },
            
            '&:not(:first-of-type)': {
                borderRadius: '12px',
                border:' 1px solid'
              },
              '&:first-of-type': {
                borderRadius: '12px',
              },
            '&.Mui-disabled': {
              border: 0,
            },
          },
    },
    toggleButtonSx:{
        
        ...TypographyStyles.caption,
       
        textTransform:'none',
        fontWeight:'700',
        
    }
}