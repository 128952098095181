import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { TypographyStyles } from '../Styles/TypographyStyles';

import { Typography } from '@mui/material';
type BadgeType = {
    background?:string,
    m?:string,
    text?:string,
    padding?:string,
    fontSize?:string,
    children:ReactNode
}

const Badge = (props: BadgeType) => {
    const{
        m=1,
        background="linear-gradient(270deg, rgba(24, 152, 254, 1) 0%, rgba(53, 209, 177, 1) 100%)",
        padding="8px 12px 8px 12px",
        fontSize=TypographyStyles.caption,
        children
    } = props
  return (
    <Box m={m} sx={{background:background,padding:padding,maxWidth:'130px',borderRadius:"50px",fontSize:fontSize,color:'text.secondary'}}>
      <Typography  sx={{...TypographyStyles.caption,fontWeight:'700'}}>
          {children} 
        </Typography>
      
    </Box>
  )
}

export default Badge