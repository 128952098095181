import React from 'react';
import Box from '@mui/material/Box';
import {Typography } from '@mui/material';

import { TypographyStyles } from '../Styles/TypographyStyles';

import Badge from '../Common/Badge';
const {motion} = require('framer-motion')
type InfoCardType={
    image?:string,
    badgeTitle?:string,
    contentTitle?:string,
    content?:string
    reverse?:boolean
    imageWidth?:string
    hideVectors?:boolean
}

const InfoCard = (props: InfoCardType) => {
    const {
        badgeTitle,
        image,
        contentTitle,
        content,
        reverse,
        imageWidth,
        hideVectors=false
    } = props;
    const [isAnimationComplete, setAnimationComplete] = React.useState(false);
    React.useEffect(()=>{
        setAnimationComplete(true)
    },[])
  return(
    <>
            <motion.div
                style={{margin:'auto'}}
                initial={{
                 x: 0,
                 y: 0,
                 scale:0.5
               }}
               animate={{
                 x: isAnimationComplete ? 0 : 800,
                 scale: isAnimationComplete ? 1 : 0.5,
                
               }}
               transition={{
                
                 duration:0.4,
                 stiffness: 460,
                 damping: 20
               }}
               exit={{
                 x: 800,
                 scale: 0.5,
               }}
               >
        <Box m={2} sx={{display:'flex',justifyContent:'space-around',justifySelf:'center',background:'transparent',flexDirection:{xs:'column',md:`${reverse?`row-reverse`:`row`}`},alignItems:'center',maxWidth:'1440px',minWidth:{md:'800px'},mx:'auto'}}>
            {/* Image container */}
            
            <Box m={2} sx={{ position: 'relative', backgroundColor: 'primary.main', borderRadius: '2000px', minWidth: { xs: '300px', md: '460px' }, minHeight: { xs: '300px', md: '460px' }, overflow: 'hidden' }}>
              {
                !hideVectors && 
                <Box sx={{ position: 'absolute', bottom: '5%', left: '50%', transform: 'translateX(-50%) translateY(1%)', width: '100%', height: '100%', maxWidth: {xs:'280px',md:'336px'}, maxHeight: {xs:'270px',md:'339px'} }}>
                  <img src='https://res.cloudinary.com/dp3hktn91/image/upload/v1690390135/Medical%20Match/Logos/info_mm_utzzmh.png' style={{ width: '100%', height: '100%' }} alt="" />
                </Box>
              }
              <Box sx={{ position: 'absolute', bottom: '7%', left: '50%', transform: 'translateX(-50%) translateY(-1%) scale(1.75) scaleX(-1) ',width:`${imageWidth}` }}>
                <img src={image} style={{ width: '100%', height: '100%' }} alt=""/>
              </Box>
              {
                !hideVectors &&        
                <Box sx={{ position: 'absolute', bottom: '20%',right:'10%', width: '100%', height: '100%', maxWidth: '52px', maxHeight: '56px' }}>
                  <img src='https://res.cloudinary.com/dp3hktn91/image/upload/v1690390135/Medical%20Match/Logos/info_mm_small_k8gyah.png' style={{ width: '100%', height: '100%' }} alt="" />
                </Box>
              }
            </Box>

            {/* Content Container */}
            <Box sx={{display:'flex',width:{xs:'100%',md:'50%'},justifyContent:{xs:'center',md:'flex-start'},alignItems:'center',flexDirection:'column',maxWidth:'740px'}}>
                <Box sx={{width:'100%',display:'flex',justifyContent:{xs:'center',md:'flex-start'},alignItems:'center'}}>

                <Badge>{badgeTitle}</Badge>
                </Box>
                <Typography  m={1} sx={{width:'100%',...TypographyStyles.heading4,fontFamily:'Outfit',fontWeight:800,textAlign:{xs:'center',md:'start'},zIndex:3,lineHeight:{xs:'28px',md:'45px'}}}>
                    {contentTitle}
                </Typography>
                <Typography  m={1} sx={{width:'100%',...TypographyStyles.body1,fontFamily:'Outfit',fontWeight:400,textAlign:{xs:'center',md:'start'},zIndex:3}}>
                    {content}
                </Typography>
            </Box>
        </Box>
            </motion.div>
    </>
  ) ;
};

export default InfoCard;
