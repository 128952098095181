import * as React from "react";
import { Dialog, Typography } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import type { PDFDocumentProxy } from 'pdfjs-dist';

interface LegalProps {
  open: boolean;
  onClick: () => void;
  fileName: string;
}

type PDFFile = string | File | null;

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

export default function Legal(props: LegalProps): JSX.Element {
  const { open, onClick, fileName } = props;

  const [ file, setFile ] = React.useState<PDFFile>();
  const [numPages, setNumPages] = React.useState<number>();

  function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
  }

  return (
    <Dialog 
        open={open}
        onClose={onClick}
    >
      <Typography variant="h3">
        <Document file={fileName} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </Typography>
    </Dialog>
  );
}