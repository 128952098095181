import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import { TypographyStyles } from './Styles/TypographyStyles';
import { FormStyles } from './Styles/FormStyles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CustomTextField from './Common/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CustomButton from './Common/Button';
import CircularProgress from '@mui/material/CircularProgress';
import "./Styles/captcha.css"
import { sendEmail } from '../Utils/SendEmail';
import ReCAPTCHA from "react-google-recaptcha";
import FormContext from '../Contexts/FormContext';
import Lottie from 'react-lottie'
import Checkmark from "../Assets/Lotties/Checkmark.json"
const {motion} = require('framer-motion')


interface IFormProps {
}

const Form: React.FunctionComponent<IFormProps> = (props) => {
    const captchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
 
    const {userType,
        setUserType,
        userProfession,
        setUserProfession,
        userFacility,
        setUserFacility,
        userState,
        setUserState,
        recaptchaValue,
        setRecaptchaValue,
        captchaVisible,
        setCaptchaVisible,
        isLoading,
        setIsLoading,
        formData,
        setFormData,
        isSubmitted,
        setIsSubmitted} = useContext(FormContext)
    useEffect(()=>{
        
        if ((formData.userEmail && formData.userName && formData.userState && (userProfession || userFacility))!==""){
            setCaptchaVisible(true)
        }
        setFormData((prevData:typeof formData) => ({
            ...prevData,
            userType:userType,
            userProfession:userProfession,
            userFacility:userFacility,
            userState:userState
          }));
        
    },[userProfession,userFacility,formData.userName,formData.userEmail,userState])
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Checkmark ,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    const handleRecaptchaChange = (value: string | null) => {
        setRecaptchaValue(value);
      };
    const handleProfession = (event: SelectChangeEvent) => {
       
        setUserProfession(event.target.value);
    };
    const handleUserState = (event: SelectChangeEvent) => {
      setUserState(event.target.value);
  };
    const handleFacility = (event: SelectChangeEvent) => {
       
        setUserFacility(event.target.value);
    };
    const handleUserType = (
        event: React.MouseEvent<HTMLElement>,
        newUserType: string,
      ) => {
        if (newUserType !== null) {
            setUserType(newUserType);
            setUserProfession("");
            setUserFacility("");
          }
      };
    const handleNameChange = (value:string) => {
            setFormData((prevData:typeof formData) => ({
                ...prevData,
                userName: value,
              }));
    };
    const handleEmailChange = (value:string) => {
        setFormData((prevData:typeof formData) => ({
            ...prevData,
            userEmail: value,
          }));
    };
    const handleSubmit=async(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); 
        
        
        if (formData.userEmail && formData.userName && formData.userState) {
            if (userType === "clinician" && !formData.userProfession) {
              alert("Please enter your profession!");
              return;
            } else if (userType === "healthFacility" && !formData.userFacility) {
              alert("Please enter your health facility!");
              return;
            }
          
            if (!recaptchaValue) {
              setCaptchaVisible(true);
              alert("Please complete the CAPTCHA!");
              return;
            } else {
              setIsLoading(true);
              try {
                const response = await sendEmail(formData);
                if (response.status === 200) {
                  setIsSubmitted(true);
                  setFormData((prevData:any) => ({
                    ...prevData,
                    userName: "",
                    userEmail: "",
                    userProfession: "",
                    userFacility: "",
                    userState:""
                  }));
                  setIsLoading(false);
                }
              } catch (error:any) {
                if (error.status === 412) {
                  alert("Please enter a valid email!");
                  setFormData((prevData:any) => ({
                    ...prevData,
                    userEmail: "",
                  }));
                  setIsLoading(false);
                }
                else{
                  alert('Something went wrong, Contact us - support@medicalmatch.io')
                }
              }
            }
          } else {
            alert("Please fill in all required fields!");
          }
        
        
      };
    
    // if(!isSubmitted){
    //     return (
    //         <>
    //             <Box sx={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center'}}>
    //             <Lottie 
    //                 options={defaultOptions}
    //                 height={250}
    //                 width={250}
    //             />
    //             </Box>
    //         </>
    //     )
    // }
  return(
    <>
        
        <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off" sx={{display:'flex',mx:'auto',height:'100%',justifyContent:'space-around',flexDirection:'column',alignItems:'center',maxWidth:'340px'}}>
            {
                !isSubmitted && 
                <>
                    <CustomTextField
                        
                        title="Full name"
                        name="userName"
                        placeholder="e.g John Doe"
                        value={formData.userName}
                        onChange={handleNameChange}
                        />
                    <CustomTextField 
                        title="Email"
                        name="userName"
                        placeholder="e.g johndoe@mail.com"
                        value={formData.userEmail}
                        onChange={handleEmailChange}
                        />
                    

                    <Box m={0.5} sx={{width:'100%'}}>
                        <Typography ml={1.5}  sx={{...TypographyStyles.caption,fontWeight:'400'}}>I'm registering as a</Typography>
                        <ToggleButtonGroup
                            sx={{...FormStyles.toggleButtonGroupSx,}}
                            color="primary"
                            value={userType}
                            exclusive
                            onChange={handleUserType}
                            defaultValue="clinician"
                            // aria-label="Platform"
                            >
                            <ToggleButton value="clinician" sx={FormStyles.toggleButtonSx}>Clinician</ToggleButton>
                            <ToggleButton value="healthFacility" sx={FormStyles.toggleButtonSx}>Health Facility</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Box m={0.5}>
                        <InputLabel id="userProfession" shrink={false} sx={FormStyles.inputSx}>
                        <Typography mb={.5} sx={{...TypographyStyles.caption,fontWeight:'400',color:'black'}} >State</Typography>
                        </InputLabel>
                        
                        <Select
                          id="userState"
                          value={userState === "" ? "Select a State" : userState}
                          label="userState"
                          onChange={handleUserState}
                          sx={{ ...FormStyles.selectSx, opacity: `${userState === "" ? 0.5 : 1}` }}
                          renderValue={(userState) =>
                            userState
                          }
                        >
                          <MenuItem value={''} sx={{ opacity: '0.5' }} disabled>
                            Select a State
                          </MenuItem>
                          <MenuItem value={"Alabama"}>Alabama</MenuItem>
                          <MenuItem value={"Alaska"}>Alaska</MenuItem>
                          <MenuItem value={"Arizona"}>Arizona</MenuItem>
                          <MenuItem value={"Arkansas"}>Arkansas</MenuItem>
                          <MenuItem value={"California"}>California</MenuItem>
                          <MenuItem value={"Colorado"}>Colorado</MenuItem>
                          <MenuItem value={"Connecticut"}>Connecticut</MenuItem>
                          <MenuItem value={"Delaware"}>Delaware</MenuItem>
                          <MenuItem value={"Florida"}>Florida</MenuItem>
                          <MenuItem value={"Georgia"}>Georgia</MenuItem>
                          <MenuItem value={"Hawaii"}>Hawaii</MenuItem>
                          <MenuItem value={"Idaho"}>Idaho</MenuItem>
                          <MenuItem value={"Illinois"}>Illinois</MenuItem>
                          <MenuItem value={"Indiana"}>Indiana</MenuItem>
                          <MenuItem value={"Iowa"}>Iowa</MenuItem>
                          <MenuItem value={"Kansas"}>Kansas</MenuItem>
                          <MenuItem value={"Kentucky"}>Kentucky</MenuItem>
                          <MenuItem value={"Louisiana"}>Louisiana</MenuItem>
                          <MenuItem value={"Maine"}>Maine</MenuItem>
                          <MenuItem value={"Maryland"}>Maryland</MenuItem>
                          <MenuItem value={"Massachusetts"}>Massachusetts</MenuItem>
                          <MenuItem value={"Michigan"}>Michigan</MenuItem>
                          <MenuItem value={"Minnesota"}>Minnesota</MenuItem>
                          <MenuItem value={"Mississippi"}>Mississippi</MenuItem>
                          <MenuItem value={"Missouri"}>Missouri</MenuItem>
                          <MenuItem value={"Montana"}>Montana</MenuItem>
                          <MenuItem value={"Nebraska"}>Nebraska</MenuItem>
                          <MenuItem value={"Nevada"}>Nevada</MenuItem>
                          <MenuItem value={"New Hampshire"}>New Hampshire</MenuItem>
                          <MenuItem value={"New Jersey"}>New Jersey</MenuItem>
                          <MenuItem value={"New Mexico"}>New Mexico</MenuItem>
                          <MenuItem value={"New York"}>New York</MenuItem>
                          <MenuItem value={"North Carolina"}>North Carolina</MenuItem>
                          <MenuItem value={"North Dakota"}>North Dakota</MenuItem>
                          <MenuItem value={"Ohio"}>Ohio</MenuItem>
                          <MenuItem value={"Oklahoma"}>Oklahoma</MenuItem>
                          <MenuItem value={"Oregon"}>Oregon</MenuItem>
                          <MenuItem value={"Pennsylvania"}>Pennsylvania</MenuItem>
                          <MenuItem value={"Rhode Island"}>Rhode Island</MenuItem>
                          <MenuItem value={"South Carolina"}>South Carolina</MenuItem>
                          <MenuItem value={"South Dakota"}>South Dakota</MenuItem>
                          <MenuItem value={"Tennessee"}>Tennessee</MenuItem>
                          <MenuItem value={"Texas"}>Texas</MenuItem>
                          <MenuItem value={"Utah"}>Utah</MenuItem>
                          <MenuItem value={"Vermont"}>Vermont</MenuItem>
                          <MenuItem value={"Virginia"}>Virginia</MenuItem>
                          <MenuItem value={"Washington"}>Washington</MenuItem>
                          <MenuItem value={"West Virginia"}>West Virginia</MenuItem>
                          <MenuItem value={"Wisconsin"}>Wisconsin</MenuItem>
                          <MenuItem value={"Wyoming"}>Wyoming</MenuItem>
                        </Select>
                            </Box>

                    <Box m={0.5}>
                      
                        
                        {
                            userType==="clinician" &&
                        <Box>
                        <InputLabel id="userProfession" shrink={false} sx={FormStyles.inputSx}>
                        <Typography mb={.5} sx={{...TypographyStyles.caption,fontWeight:'400',color:'black'}} >Profession</Typography>
                        </InputLabel>
                        
                        <Select
                        
                            id="userProfession"
                            value={userProfession===""?"Select a Profession":userProfession}
                            
                            label="userProfession"
                            // placeholder='eg. Nurse'
                            onChange={handleProfession}
                            sx={{...FormStyles.selectSx,opacity:`${userProfession===""?0.5:1}`}}
                            renderValue={userProfession=>userProfession}
                        >   
                            
                                <MenuItem value={''} sx={{opacity:'0.5'}} disabled>
                                    Select a profession
                                </MenuItem>
                                <MenuItem value={"Pharmacy Technician"}>Pharmacy Technician</MenuItem>
                                <MenuItem value={"Pharmacy Provider"}>Pharmacy Provider</MenuItem>
                                <MenuItem value={"Registered Nurse"}>Registered Nurse</MenuItem>
                                <MenuItem value={"Licensed Practitioner Nurse"}>Licensed Prac. Nurse / Licensed Vocational Nurse</MenuItem>
                                <MenuItem value={"Nursing Assistant"}>Nursing Assistant</MenuItem>
                                <MenuItem value={"Medical Assistant"}>Medical Assistant</MenuItem>
                                <MenuItem value={"Physical Therapist"}>Physical Therapist</MenuItem>
                                <MenuItem value={"Physical Therapy Technician"}>Physical Therapy Technician</MenuItem>
                                <MenuItem value={"Occupational Therapist"}>Occupational Therapist</MenuItem>
                                <MenuItem value={"Occupational Therapy Technician"}>Occupational Therapy Technician</MenuItem>
                                <MenuItem value={"Speech Language Therapist"}>Speech Language Therapist</MenuItem>
                                <MenuItem value={"Speech Language Technician"}>Speech Language Technician</MenuItem>
                                <MenuItem value={"Respiratory Therapist"}>Respiratory Therapist</MenuItem>
                                <MenuItem value={"Social Worker"}>Social Worker</MenuItem>
                                <MenuItem value={"Surgical Technologist"}>Surgical Technologist</MenuItem>
                                <MenuItem value={"Dental Assistant"}>Dental Assistant</MenuItem>
                                <MenuItem value={"Billing Coding Professional"}>Billing / Coding Professional</MenuItem>
                                <MenuItem value={"Health Information Professional"}>Health Information Professional</MenuItem>
                                <MenuItem value={"Quality and Outcomes Professional"}>Quality and Outcomes Professional</MenuItem>
                                <MenuItem value={"Research Coordinator"}>Research Coordinator</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                            </Box>
                        }
                        {
                            userType==="healthFacility" &&
                        <Box>
                        <InputLabel id="healthFacility" shrink={false} sx={FormStyles.inputSx}>
                        <Typography mb={.5} sx={{...TypographyStyles.caption,fontWeight:'400',color:'black'}}>Facility Type</Typography>
                        </InputLabel>
                        
                        <Select
                        
                            id="userFacility"
                            value={userFacility===""?"Select a Facility":userFacility}
                            
                            label="userFacility"
                            // placeholder='eg. Nurse'
                            onChange={handleFacility}
                            sx={{...FormStyles.selectSx,opacity:`${userFacility===""?0.5:1}`}}
                            renderValue={userFacility=>userFacility}
                        >   
                            
                            <MenuItem value={''} sx={{ opacity: '.5' }} disabled>
                            Select a Facility
                            </MenuItem>
                            <MenuItem value={"Hospital"}>Hospital</MenuItem>
                            <MenuItem value={"Clinic"}>Clinic</MenuItem>
                            <MenuItem value={"Pharmacy"}>Pharmacy</MenuItem>
                            <MenuItem value={"Laboratory"}>Laboratory</MenuItem>
                            <MenuItem value={"Nursing Home"}>Nursing Home</MenuItem>
                            <MenuItem value={"Physical Therapy Center"}>Physical Therapy Center</MenuItem>
                            <MenuItem value={"Occupational Therapy Center"}>Occupational Therapy Center</MenuItem>
                            <MenuItem value={"Rehabilitation Center"}>Rehabilitation Center</MenuItem>
                            <MenuItem value={"Ambulatory Surgical Center"}>Ambulatory Surgical Center</MenuItem>
                            <MenuItem value={"Urgent Care Center"}>Urgent Care Center</MenuItem>
                            <MenuItem value={"mentalHealthFacility"}>Mental Health Facility</MenuItem>
                            <MenuItem value={"Mental Health Facility"}>Maternity Center</MenuItem>
                            <MenuItem value={"outpatientCareCenter"}>Outpatient Care Center</MenuItem>
                            <MenuItem value={"Outpatient Care Center"}>Hospice</MenuItem>
                            <MenuItem value={"Home Health Agency"}>Home Health Agency</MenuItem>
                            <MenuItem value={"Dental Clinic"}>Dental Clinic</MenuItem>
                            <MenuItem value={"Blood Bank"}>Blood Bank</MenuItem>
                            <MenuItem value={"Optometry Clinic"}>Optometry Clinic</MenuItem>
                            <MenuItem value={"Chiropractic Clinic"}>Chiropractic Clinic</MenuItem>

                            </Select>
                            </Box>
                        }
                    </Box>
                    <Box mt={1} sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',mx:'auto'}}>

                    <Box sx={{display:`${captchaVisible?'flex':'none'}`, width:'100%'}} mt={1}>
                        <ReCAPTCHA
                            sitekey={captchaSiteKey||''} 
                            onChange={handleRecaptchaChange}
                            
                            className='captcha'
                            
                            />
                    </Box>
                        <CustomButton type="submit"  >
                    {
                        isLoading?
                        <>
                        <CircularProgress color="inherit"/>
                        </>
                        :
                            "Join the waitlist"
                        }
                        </CustomButton>
                    
                    </Box>
                </>
            }
           
            {
                isSubmitted &&
                <>
                
                <motion.div
                    initial={{ rotate: 180,scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: 260,
                      damping: 20
                    }}>
                    <Box  sx={{display:'flex',width:'100%',justifyContent:'space-around',alignItems:'center',flexDirection:'column',minWidth:{sm:'420px'},minHeight:{sm:'430px'}}}>
                    <Typography   sx={{mx:'auto',...TypographyStyles.heading4,fontFamily:'Outfit',fontWeight:800,textAlign:'center',zIndex:3,lineHeight:{xs:'28px',md:'45px'},maxWidth:{xs:'190px',sm:'260px'}}}>
                    Welcome to MedicalMatch!
                    </Typography>
                        <Lottie 
                            options={defaultOptions}
                            height={200}
                            width={240}
                        />
                    </Box>
                </motion.div>
               
                
                </>
            }
            
        </Box>
    </>
  ) ;
};

export default Form;
