import React,{useState} from 'react';
import Box from '@mui/material/Box';
import {Typography } from '@mui/material';

import { TypographyStyles } from './../Styles/TypographyStyles';
import Accordian from './Accordian';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ClinicIcon from "../../Assets/Icons/ClinicIcon"
import ClinicianIcon from "../../Assets/Icons/ClinicianIcon"
import { FormStyles } from '../Styles/FormStyles';

type FaqType= {
  faqContent:any,
  infoType:string,
  setInfoType:Function
}

const Faq= (props:FaqType) => {
  const {
    infoType,
    faqContent,
    setInfoType
}=props
  
  const handleInfoType = (
    event: React.MouseEvent<HTMLElement>,
    newInfoType: string,
  ) => {
    if (newInfoType !== null) {
      setInfoType(newInfoType);
    }
  };
  console.log(infoType)
  return (
    <>
        <Box mb={3} sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
            <Typography  m={2} sx={{width:'100%',...TypographyStyles.heading4,fontFamily:'Outfit',fontWeight:800,textAlign:'center',color:'text.primary',zIndex:3,mx:'auto'}}>
                {faqContent.title}
            </Typography>
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <ToggleButtonGroup
                    sx={{...FormStyles.toggleButtonGroupSx,zIndex:1}}
                    color="primary"
                    value={infoType}
                    exclusive
                    onChange={handleInfoType}
                    defaultValue="clinician"
                    // aria-label="Platform"
                    >
                    <ToggleButton value="clinician" sx={{...FormStyles.toggleButtonSx,display:'flex',justifyContent:'center',alignItems:'center',padding:'10px 15px 10px 15px',fontColor:'black'}}>
                        <ClinicianIcon fill={`${infoType==="clinician"?'#004DFE':'text.primary'}`} width="24"/>
                        <Typography mx={.2} sx={{...TypographyStyles.caption,color:`${infoType==="clinician"?'primary.main':'text.primary'}`,fontWeight:700,}}>
                            For Clinicians
                        </Typography>
                    </ToggleButton>

                    <ToggleButton value="healthFacility" sx={{...FormStyles.toggleButtonSx,display:'flex',justifyContent:'center',alignItems:'center',padding:'10px 15px 10px 15px'}}>
                        <ClinicIcon fill={`${infoType==="healthFacility"?'#004DFE':'text.primary'}`} width="24"/>
                        <Typography mx={.2} sx={{...TypographyStyles.caption,color:`${infoType==="healthFacility"?'primary.main':'text.primary'}`,fontWeight:700}}>
                            For Employers
                        </Typography>
                    </ToggleButton>

                </ToggleButtonGroup>
            </Box>
            
            <Box mt={-2} sx={{display:'flex',flexDirection:'column',maxWidth:'953px',zIndex:3,height:'auto'}}>

            {
              infoType==="clinician" &&
                  faqContent.faqClinicians.content.map((data:any,index:number)=>(
                    
                    <Accordian key={data.id}  question={data.question} answer={data.answer}/>
                
                  ))
              }
              {
                infoType==="healthFacility" &&
                  faqContent.faqOrganisations.content.map((data:any,index:number)=>(
                    <Accordian key={data.id}  question={data.question} answer={data.answer}/>
                  ))
              }
              
            </Box>
        </Box>
    </>
  );
};

export default Faq;
