import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TypographyStyles } from '../Styles/TypographyStyles';

type AccordianType={
    question?:string,
    answer?:string
}

const Accordian= (props:AccordianType) => {
    const {
        question = "",
        answer = ""
    }=props
  return (
    <>
    
        <Accordion sx={{backgroundColor:''}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontFamily:'Outfit',fontWeight:'500',color:'rgba(0, 0, 0, 1)',...TypographyStyles.body2}}>{question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontFamily:'Outfit',fontWeight:'400',color:'rgba(89, 93, 105, 1)',...TypographyStyles.body2,whiteSpace: 'pre-line' }}>
            {answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
   
    </>
  )
};

export default Accordian;
