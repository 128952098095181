import * as React from 'react';
import Box from '@mui/material/Box';
import {Typography } from '@mui/material';
import { TypographyStyles } from './Styles/TypographyStyles';
import CustomButton from './Common/Button';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Legal from '../Components/Legal';

type FooterType={
}

const Footer = (props:FooterType) => {

    // useState to set the value of inputs
    const [ openPrivacy, setOpenPrivacy ] = React.useState(false);
    const [ openTerms, setOpenTerms ] = React.useState(false);
    const [ openRefund, setOpenRefund ] = React. useState(false);
    const [ openPAA, setOpenPAA ] = React. useState(false);
    const [ openBackground, setOpenBackground ] = React. useState(false);

    // Open/close the Privacy and terms & conditions dialog 
    const openPrivacyDialog = () => setOpenPrivacy(true);
    const closePrivacyDialog = () => setOpenPrivacy(false);
    const openTermsDialog = () => setOpenTerms(true);
    const closeTermsDialog = () => setOpenTerms(false);
    const openRefundDialog = () => setOpenRefund(true);
    const closeRefundDialog = () => setOpenRefund(false);
    const openPAADialog = () => setOpenPAA(true);
    const closePAADialog = () => setOpenPAA(false);
    const openBackgroundDialog = () => setOpenBackground(true);
    const closeBackgroundDialog = () => setOpenBackground(false);

    return (
    <>
        <Box  sx={{width:'100%',backgroundColor:'primary.dark',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <Box  sx={{display:'flex',justifyContent:'space-between',maxWidth:'1440px',alignItems:{xs:'flex-start',sm:'center'},flexDirection:{xs:'column',md:'row'},width:'100%'}}>
                <Box m={1} sx={{width:{xs:'145px',sm:'310px'}}}>
                    <img src="https://res.cloudinary.com/dp3hktn91/image/upload/v1690497905/Medical%20Match/Logos/1--Vertical-Logo-Color-Transparent-Background-PNG_ctywoy.png" style={{width:'100%'}} alt="MedicalMatch"/>
                </Box>
                {/* Follow us */}
                <Box m={2} sx={{display:'flex',flexDirection:'column',maxWidth:'400px'}}>
                    <Typography  sx={{...TypographyStyles.body2,textAlign:{xs:'left',md:'center'},color:'text.secondary',fontWeight:500,zIndex:2}}>
                                Follow Us
                    </Typography> 
                    {/* Icons Container */}
                    <Box  sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <Box mb={1} sx={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',width:'100%'}}>

                        <CustomButton icon={true}>
                            <a style={{textDecoration:'none'}} href={`mailto:support@medicalmatch.io`}>

                            <FacebookIcon sx={{ color:"text.secondary" }} />
                            </a>
                        </CustomButton>
                        <CustomButton icon={true}>
                        <a style={{textDecoration:'none'}} href={`mailto:support@medicalmatch.io`}>
                            <InstagramIcon sx={{ color:"text.secondary" }} />
                        </a>
                        </CustomButton>
                        <CustomButton icon={true}>
                        <a style={{textDecoration:'none'}} href={`mailto:support@medicalmatch.io`}>

                            <TwitterIcon sx={{ color:"text.secondary" }} />
                        </a>
                        </CustomButton>
                        <CustomButton icon={true}>
                            <a style={{textDecoration:'none'}} href={`https://www.linkedin.com/company/medicalmatch.io/`}>
                                <LinkedInIcon sx={{ color:"text.secondary" }} />
                            </a>
                        </CustomButton>
                        </Box>
                    </Box>
                </Box>
                <Box m={2} sx={{display:'flex',flexDirection:'column',maxWidth:'282px'}}>
                    <Typography  sx={{...TypographyStyles.body2,textAlign:{xs:'left',md:'left'},color:'text.secondary',fontWeight:500,zIndex:2}}>
                        Contacts
                    </Typography> 
                    <Box mb={1} sx={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',width:'100%'}}>
                        <CustomButton icon={true}>
                            <CallOutlinedIcon sx={{ color:"text.secondary" }} />
                        </CustomButton>
                        <Typography  sx={{...TypographyStyles.body2,textAlign:'left',color:'text.secondary',fontWeight:400,zIndex:2}}>
                        385-484-8617
                        </Typography> 
                    </Box>
                    <Box  sx={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',width:'100%'}}>
                        <CustomButton icon={true}>
                            <LocationOnOutlinedIcon sx={{ color:"text.secondary" }} />
                        </CustomButton>
                        <a style={{textDecoration:'none'}} href={`mailto:support@medicalmatch.io`}>

                        <Typography  sx={{...TypographyStyles.body2,textAlign:'left',color:'text.secondary',fontWeight:400,zIndex:2}}>
                            support@medicalmatch.io 
                        </Typography> 
                        </a>
                    </Box>
                </Box>
            </Box>
            <Box m={2} sx={{display:'flex',width:'100%',flexDirection:'row',justifyContent:'space-around',color:'text.secondary',maxWidth:'1440px',mx:'auto'}}>
                <Typography sx={{...TypographyStyles.caption,opacity:0.5,fontWeight:'400'}}>©2023 MedicalMatch</Typography>
                <Typography sx={{...TypographyStyles.caption,opacity:0.5,fontWeight:'400',cursor:'pointer','&: hover':{textDecoration:'underline'}}} onClick={openPrivacyDialog}>Privacy Policy</Typography>
                    <Legal open={openPrivacy} onClick={closePrivacyDialog} fileName={'https://medicalmatch.io/privacy-policy.pdf'} />
                <Typography sx={{...TypographyStyles.caption,opacity:0.5,fontWeight:'400',cursor:'pointer','&: hover':{textDecoration:'underline'}}} onClick={openRefundDialog}>Refund Policy</Typography>
                    <Legal open={openRefund} onClick={closeRefundDialog} fileName={'https://medicalmatch.io/refund-policy.pdf'} />
                <Typography sx={{...TypographyStyles.caption,opacity:0.5,fontWeight:'400',cursor:'pointer','&: hover':{textDecoration:'underline'}}} onClick={openTermsDialog}>Terms & Conditions</Typography>
                    <Legal open={openTerms} onClick={closeTermsDialog} fileName={'https://medicalmatch.io/terms-and-conditions.pdf'} />
                <Typography sx={{...TypographyStyles.caption,opacity:0.5,fontWeight:'400',cursor:'pointer','&: hover':{textDecoration:'underline'}}} onClick={openPAADialog}>Platform Access Agreement</Typography>
                    <Legal open={openPAA} onClick={closePAADialog} fileName={'https://medicalmatch.io/platform-access-agreement.pdf'} />
                <Typography sx={{...TypographyStyles.caption,opacity:0.5,fontWeight:'400',cursor:'pointer','&: hover':{textDecoration:'underline'}}} onClick={openBackgroundDialog}>Background Checks and Professional Licensing</Typography>
                    <Legal open={openBackground} onClick={closeBackgroundDialog} fileName={'https://medicalmatch.io/background-checks-and-professional-licensing.pdf'} />
            </Box>

        </Box>
    </>
  );
};

export default Footer;
