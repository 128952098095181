
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { TypographyStyles } from '../Styles/TypographyStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

type BulletType={
    content?:string,
    check?:boolean
}

const Bullet=(props:BulletType) => {
    const {
        check=true,
        content=""
    }=props
  return(
    <>
    <Box sx={{width:'100%',display:'flex',justifyContent:'space-around',alignItems:'center',borderBottom:'0.5px solid' , borderColor:"#F2F2F3"}}>
        <Typography my={2} sx={{...TypographyStyles.body2,color:'text.primary',fontWeight:600,zIndex:2,textAlign:'left',width:'100%'}}>
             {content}
        </Typography> 
        <Box>
            {check?<CheckCircleIcon sx={{color:'#64E581',fontSize:'30px'}}/>:<CancelIcon sx={{color:'rgba(237, 17, 17, 1)'}}/>}
        </Box>

    </Box>
    </>
  );
};

export default Bullet;
