
import Box from '@mui/material/Box';
import {Typography } from '@mui/material';

import { TypographyStyles } from '../Styles/TypographyStyles';

import StatsCard from './StatsCard';
import statsBg from '../../Assets/Backgrounds/stats.png'
import statsBgMobile from '../../Assets/Backgrounds/stats_mobile.png'
type StatsType ={
}

const Stats = (props:StatsType) => {
  return(
    <>
        <Box mb={5} sx={{width:'100%',height:'100%',display:'flex',flexDirection:'column'}}>
                <Typography  m={2} sx={{width:'100%',...TypographyStyles.heading3,fontFamily:'Outfit',fontWeight:800,textAlign:'center',color:'text.primary',zIndex:3,mx:'auto'}}>
                    MedicalMatch by the numbers
                </Typography>
                <Box m={2}  sx={{position:'',display:'flex',width:'100%',flexDirection:{xs:'column',md:'row'},justifyContent:"center",alignItems:'center',mx:'auto',zIndex:2,backgroundImage:{xs:`Url(https://res.cloudinary.com/dp3hktn91/image/upload/v1690219458/Medical%20Match/Backgrounds/stats_mobile_e7pm36.png)`,md:`Url(https://res.cloudinary.com/dp3hktn91/image/upload/v1690219458/Medical%20Match/Backgrounds/stats_bez4iz.png)`},backgroundSize:{xs:'400px',md:'1400px'},backgroundRepeat:'no-repeat',backgroundPosition:'center'}}>
                  <StatsCard stats="+18M" content='healthcare workers in the U.S.'/>
                  <StatsCard stats="+203K" content='new RN positions each year '/>
                  <StatsCard stats="+141K" content='primary care doctor offices'/>
                </Box>
        </Box>
    </>
  ) ;
};

export default Stats;
