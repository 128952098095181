import React, {useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import {Typography } from '@mui/material';

import { TypographyStyles } from './Styles/TypographyStyles';

import Tab from '@mui/material/Tab';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';




import Bullet from './Common/Bullet';
const Comparison = () => {
  // const [isMobile,setIsMobile] = useState(false)
  // const isMobileView = () =>{
  //     return window.innerWidth<768
  // }
  // useEffect(()=>{
  //   setIsMobile(isMobileView());
  // },[])
  // useEffect(() => {
  //   console.log('width changing')
  //   function handleResize() {
  //     if (window.innerWidth<768) {
  //       setIsMobile(true)
  //     }
  //     else{
  //       setIsMobile(false)
  //     }
  //   }
  //   handleResize();
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);
  const [value, setValue] = useState('medicalmatch');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
    <Box sx={{display:'flex',width:'100%',flexDirection:'column',alignItems:'center',background:'rgba(0, 77, 254, 0.05)'}}>
      <Typography  m={2} sx={{width:'100%',...TypographyStyles.heading4,fontFamily:'Outfit',fontWeight:800,textAlign:'center',color:'text.primary',zIndex:3,mx:'auto'}}>
                   Why choose MedicalMatch?
        </Typography>
  
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',backgroundColor:'primary.dark'}}>
          <TabContext value={value}>
            <Box sx={{ display:'flex',flexDirection:'column',borderBottom: 1, borderColor: 'divider',justifyContent:'center',alignItems:'center' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" >
              {/* <Tab icon={<img src="https://res.cloudinary.com/dp3hktn91/image/upload/v1690219507/Medical%20Match/Logos/IntelyCare_yxnhc1.png" style={{width:'80px'}} alt="Intelycare"/>} aria-label="intelycare" value="intelycare" /> */}
              <Tab icon={<img src="https://res.cloudinary.com/dp3hktn91/image/upload/v1690219507/Medical%20Match/Logos/NavbarLogo_nwl1jz.svg" style={{width:'180px'}} alt="MedicalMatch"/> } color='white' aria-label="medicalmatch" value="medicalmatch" />
              {/* <Tab icon={<img src="https://res.cloudinary.com/dp3hktn91/image/upload/v1690219507/Medical%20Match/Logos/Nursa_rxxdjj.png" style={{width:'80px'}} alt="Nursa" />} aria-label="nursa" value="nursa"/> */}
              </TabList>
              <Box>

                  <Box my={3} sx={{background:'white',minWidth:{xs:'342px',sm:'600px',md:'805px'},borderRadius:'15px'}}>
                    {/* <TabPanel value="intelycare">
                      <Box>

                      <Bullet content="Centralized credentialing and education" check={false}/>
                      <Bullet content="Standardized onboarding by specialty" check={false}/>
                      <Bullet content="Serving all healthcare professionals" check={false}/>
                      <Bullet content="Built by clinicians for the healthcare workforce" check={false}/>
                      </Box>
                    </TabPanel> */}
                    <TabPanel value="medicalmatch">
                    <Bullet content="Centralized credentialing and education" />
                      <Bullet content="Standardized onboarding by specialty" />
                      <Bullet content="Serving all healthcare professionals" />
                      <Bullet content="Built by clinicians for the healthcare workforce" />
                    </TabPanel>
                    {/* <TabPanel value="nursa">
                    <Bullet content="Centralized credentialing and education" check={false}/>
                      <Bullet content="Standardized onboarding by specialty" check={false}/>
                      <Bullet content="Serving all healthcare professionals" check={false}/>
                      <Bullet content="Built by clinicians for the healthcare workforce" check={false}/>
                    </TabPanel> */}
                  </Box>
              </Box>
            </Box>
          </TabContext>
            
          </Box>
         
         
    </Box>
    </>
  );
};

export default Comparison;
