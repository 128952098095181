import React from 'react';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { TypographyStyles } from '../Styles/TypographyStyles';

interface CustomTextFieldType {
  labelColor?: string;
  title: string;
  placeholder: string;
  value: string; // Add the value prop
  name:string;
  onChange: (value: string) => void; // Add the onChange callback prop
}

const CustomTextField = (props: CustomTextFieldType) => {
  const {
    placeholder,
    title,
    labelColor = 'rgba(255, 255, 255, 0.5)',
    value, // Add value to destructure from props
    onChange, // Add onChange to destructure from props
  } = props;

  const [focused, setFocused] = React.useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const textFieldSx = {
    '& .MuiInputBase-input': {
      backgroundColor: 'rgba(242, 242, 243, 1)',
      border: 'px',
      borderRadius: '12px',
    },
    '& .MuiOutlinedInput-root': {
      border: '0px',
      borderRadius: '12px',
      borderColor: 'rgba(255, 255, 255, 0.5)',
      '&:hover fieldset': {
        borderColor: 'primary.main',
      },
    },
  };

  const styledInputLabelProps = {
    style: {
      color: focused ? labelColor : 'rgba(155, 15, 55, 0.5)',
    },
    position: 'above',
    required: true,
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange(newValue); // Call the onChange callback with the new value
  };

  return (
    <>
      <Box m={0.8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', minWidth: { md: '120px' }, maxWidth: { xs: '320px' } }}>
        <Typography sx={{ ...TypographyStyles.caption, fontWeight: '400', marginBottom: '3px' }}>
          {title}
        </Typography>
        <TextField
            required={true}
          sx={textFieldSx}
          InputLabelProps={styledInputLabelProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          value={value} // Set the value prop
          onChange={handleChange} // Set the onChange callback
        />
      </Box>
    </>
  );
};

export default CustomTextField;
