export const TypographyStyles = {
  heading1: {
    fontSize: { xs: "2.0rem", md: "4rem" },
  },
  heading2: {
    fontSize: { xs: "1.8rem", md: "3.5rem" },
  },
  heading3: {
    fontSize: { xs: "1.4rem", md: "2.18rem" },
  },
  heading4: {
    fontSize: { xs: "1.1rem", md: "1.8rem" },
  },
  body1: {
    fontSize: { xs: "1.0rem", md: "1.25rem" },
  },
  body2: {
    fontSize: { xs: "0.95rem", md: "1.1rem" },
  },
  caption: {
    fontSize: { xs: "0.70rem", md: "0.9rem" },
  },
  overline: {
    fontSize: { xs: "0.7rem", md: "0.8rem" },
  },
};
