
import Box from '@mui/material/Box';
import {Typography } from '@mui/material';
import { TypographyStyles } from './Styles/TypographyStyles';

type PartnersType= {
    logos?:[string]
}

const Partners = (props:PartnersType) => {
    const{
        logos=[
            "https://res.cloudinary.com/dp3hktn91/image/upload/v1690219457/Medical%20Match/Backgrounds/partners1_lcwvwf.png",
            "https://res.cloudinary.com/dp3hktn91/image/upload/v1690219457/Medical%20Match/Backgrounds/partners2_geu8dk.png",
            "https://res.cloudinary.com/dp3hktn91/image/upload/v1690219458/Medical%20Match/Backgrounds/partners3_pctppa.png",
            "https://res.cloudinary.com/dp3hktn91/image/upload/v1690219458/Medical%20Match/Backgrounds/partners4_ukrsxl.png",
            "https://res.cloudinary.com/dp3hktn91/image/upload/v1690219458/Medical%20Match/Backgrounds/partners5_ngbmlv.png",
            "https://res.cloudinary.com/dp3hktn91/image/upload/v1690219458/Medical%20Match/Backgrounds/partners6_vt7ocr.png",
            "https://res.cloudinary.com/dp3hktn91/image/upload/v1690219458/Medical%20Match/Backgrounds/partners7_wcx8nn.png"
        ]
    }=props
  return(
    <>
        <Box sx={{display:'flex',justifyContent:'center',alignItems:'flex-start',width:'100%',backgroundColor:'primary.dark'}}>
            <Box m={4} sx={{display:'flex',width:'100%',maxWidth:'1440px',justifyContent:'space-between',alignItems:{xs:'center',md:'flex-start'},flexDirection:{xs:'column',md:'row'},backgroundImage:{xs:'Url(https://res.cloudinary.com/dp3hktn91/image/upload/v1690291796/Medical%20Match/Backgrounds/partnersBgMobile_l3uurl.png)',md:`Url(https://res.cloudinary.com/dp3hktn91/image/upload/v1690219457/Medical%20Match/Backgrounds/partners_iaxr0m.png)`},backgroundSize:{xs:'310px',sm:'1700px'},backgroundRepeat:'no-repeat',backgroundPosition:'center'}}>
                <Typography  m={2} sx={{width:'100%',...TypographyStyles.heading3,fontFamily:'Outfit',fontWeight:800,textAlign:{xs:'center',md:'start'},color:'text.secondary',zIndex:3,maxWidth:'368px'}}>
                    Our trusted partners
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap',width:'100%', maxWidth: '892px', justifyContent: {xs:'center',md:'flex-end'} }}>
                {logos 
                    .map((logo:any, index) => (
                    <Box key={index} m={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: {xs:'130px',md:'180px'}, height: {xs:'110px',md:'180px'}, background: 'rgba(247, 247, 247, 1)',borderRadius:'12px' }}>
                        <img src={logo} style={{ width: '100%', maxWidth: '100px', padding: '24px' }} alt={`${index}`}/>
                    </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    </>
  );
};

export default Partners;
