import React,{useRef,useState} from 'react';
import Box from '@mui/material/Box';
import {Typography } from '@mui/material';
import { TypographyStyles } from './Styles/TypographyStyles';

import Typed from 'typed.js';
import { motion} from 'framer-motion';
import Form from './Form';

interface IHeroProps {

}

const Hero: React.FunctionComponent<IHeroProps> = (props) => {
    const el = useRef(null);
    const ref=useRef(null)
    const [isAnimationComplete, setAnimationComplete] = useState(false);
    const [isMobile,setIsMobile] = useState(false)
    const [scaleVariants,setScaleVariants]=useState()
    
  
    // const { scrollYProgress } = useScroll({
      //   target: ref,
      
      // });
      // const [slideDownAnimation,setSlideDownAnimation]=useState(false)
      // useMotionValueEvent(scrollYProgress, "change", (latest) => {
        //   if(latest>=0.8){
          //     setSlideDownAnimation(true)
          //   }
          //   else{
      //     setSlideDownAnimation(false)
      //   }
      // })
      React.useEffect(() => {
        
         function handleResize() {
           if (window.innerWidth<896) {
             setIsMobile(true)
            }
            else{
              setIsMobile(false)
           }
         }
         handleResize();
         window.addEventListener('resize', handleResize);
         setAnimationComplete(true)
        const typed = new Typed(el.current, {
          strings: ['Anytime,^1000 Anywhere.'],
          typeSpeed: 40,
          loop:true,
          backDelay:60000,
          startDelay:200,
          fadeOut:true,
          
          //   showCursor:false,
          cursorChar: '|',
          
          onStart: (arrayPos,typedInstance) => {
            // The onInit callback provides access to the Typed.js instance
            const cursor = typedInstance.cursor;
            
            // cursor.style.animationDelay="7000";
           
            cursor.style.visibility = 'visible';  // Hide the cursor at the start of typing
            
        },
          onStringTyped: (arrayPos, typedInstance) => {
            // The onStringTyped callback also provides access to the Typed.js instance
            const cursor = typedInstance.cursor;
            cursor.style.visibility = 'hidden'; // Hide the cursor after typing is complete
          },
        });
        return () => {
          // Destroy Typed instance during cleanup to stop animation
          typed.destroy();
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  return(
    <>
        <Box ref={ref}>
            {/* Content & Form */}
            <Box   sx={{width:'100%',backgroundColor:'primary.dark',backgroundImage: `url(https://res.cloudinary.com/dp3hktn91/image/upload/v1690219458/Medical%20Match/Backgrounds/arrows_k1jvyq.png)`,backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundPosition: 'unset',zIndex:2}}>
              {
                window.innerWidth<896? //using window attribute instead of state for initial render
                <>
                  <Box  sx={{width:{xs:'100%',sm:'100%'},display:'flex',justifyContent:'space-evenly',alignItems:'center',flexDirection:{xs:'column',md:"row"},maxWidth:'1680px',mx:"auto",zIndex:2}}>
                    {/* Content */}
                    <Typography m={1} component="h1" sx={{...TypographyStyles.heading1,maxWidth:{xs:'342px',sm:'500px',md:'752px'},color:'text.secondary',fontWeight:800,lineHeight:{xs:'40px',md:'70px'},textAlign:{xs:'center',md:'left'}}}>
                        Disrupting healthcare staffing.<br/>
                          
                        <Typography my={1}  
                                    ref={el}
                                    component="span"
                                    sx={{...TypographyStyles.heading1,fontWeight:800,lineHeight:{xs:'40px',md:'70px'},
                                        background:'-webkit-linear-gradient(0deg, rgba(236, 249, 85, 1) 25%, rgba(124, 239, 103, 1) 50%, rgba(53, 209, 177, 1) 75%,rgba(31, 163, 154, 1) 100%,  rgba(53, 209, 177, 1) 100%)',WebkitBackgroundClip: "text",WebkitTextFillColor: "transparent"}}>
                            <b>Anytime, Anywhere.</b>
                        </Typography>
                        <Typography my={isMobile?2:3} component="h2" sx={{...TypographyStyles.heading4,color:'text.secondary',fontWeight:500,zIndex:2}}>
                            <Typography mr={1} component="span" sx={{...TypographyStyles.heading4,color:'text.secondary',fontWeight:800,zIndex:2,background:'-webkit-linear-gradient(0deg, rgba(236, 249, 85, 1) 25%, rgba(124, 239, 103, 1) 50%, rgba(53, 209, 177, 1) 75%,rgba(31, 163, 154, 1) 100%,  rgba(53, 209, 177, 1) 100%)',WebkitBackgroundClip: "text",WebkitTextFillColor: "transparent"}}>
                            One-Time Shift Solutions
                            </Typography>
                             bringing freedom to all healthcare professionals.
                        </Typography>
                    </Typography>
                    {/* Form */}
                    
                      <Box mt={isMobile?1:3} sx={{maxWidth:'535px',borderRadius:{xs:'20px',md:'40px'},backgroundColor:'text.secondary',paddingX:{xs:'10px',md:'40px'},paddingY:{xs:'10px',md:'20px'},zIndex:2}}>
                          <Form/>
                      </Box>
                </Box>
                <Box sx={{width:'30vw',height:{xs:'150px',md:'350px'}, borderRadius: '50%',background: 'linear-gradient(0deg, rgba(236, 249, 85, 1) 25%, rgba(124, 239, 103, 1) 50%, rgba(53, 209, 177, 1) 75%,rgba(31, 163, 154, 1) 100%,  rgba(53, 209, 177, 1) 100%)', filter: "blur(225px)",mx:'auto',zIndex:-10}}></Box>
                </>
                :
              <motion.div  style={{zIndex:3}}
                      initial={{
                        x: 0,
                        y: 0,
                        scale: 0.8
                      }}
                      animate={{
                        scale: isAnimationComplete ? 1 : 0.8,
                      }}
                      transition={{
                        duration: 0.9,
                        stiffness: 460,
                        damping: 20
                      }}
                    >
                <Box  sx={{width:{xs:'100%',sm:'100%'},display:'flex',justifyContent:'space-evenly',alignItems:'center',flexDirection:{xs:'column',md:"row"},maxWidth:'1680px',mx:"auto",zIndex:2}}>
                    {/* Content */}
                    <Typography m={1} component="h1" sx={{...TypographyStyles.heading1,maxWidth:{xs:'342px',sm:'500px',md:'752px'},color:'text.secondary',fontWeight:800,lineHeight:{xs:'40px',md:'70px'},textAlign:{xs:'center',md:'left'}}}>
                        Disrupting healthcare staffing.<br/>
                          
                        <Typography my={1}  
                                    ref={el}
                                    component="span"
                                    sx={{...TypographyStyles.heading1,fontWeight:800,lineHeight:{xs:'40px',md:'70px'},
                                        background:'-webkit-linear-gradient(0deg, rgba(236, 249, 85, 1) 25%, rgba(124, 239, 103, 1) 50%, rgba(53, 209, 177, 1) 75%,rgba(31, 163, 154, 1) 100%,  rgba(53, 209, 177, 1) 100%)',WebkitBackgroundClip: "text",WebkitTextFillColor: "transparent"}}>
                            <b>Anytime, Anywhere.</b>
                        </Typography>
                        <Typography my={isMobile?2:3} component="h2" sx={{...TypographyStyles.heading4,color:'text.secondary',fontWeight:500,zIndex:2}}>
                            <Typography mr={1} component="span" sx={{...TypographyStyles.heading4,color:'text.secondary',fontWeight:800,zIndex:2,background:'-webkit-linear-gradient(0deg, rgba(236, 249, 85, 1) 25%, rgba(124, 239, 103, 1) 50%, rgba(53, 209, 177, 1) 75%,rgba(31, 163, 154, 1) 100%,  rgba(53, 209, 177, 1) 100%)',WebkitBackgroundClip: "text",WebkitTextFillColor: "transparent"}}>
                            One-Time Shift Solutions
                            </Typography>
                             bringing freedom to all healthcare professionals.
                        </Typography>
                    </Typography>
                    {/* Form */}
                    
                      <Box mt={isMobile?1:3} sx={{maxWidth:'535px',borderRadius:{xs:'20px',md:'40px'},backgroundColor:'text.secondary',paddingX:{xs:'10px',md:'40px'},paddingY:{xs:'10px',md:'20px'},zIndex:2}}>
                          <Form/>
                      </Box>
                </Box>
                <Box sx={{width:'30vw',height:{xs:'150px',md:'350px'}, borderRadius: '50%',background: 'linear-gradient(0deg, rgba(236, 249, 85, 1) 25%, rgba(124, 239, 103, 1) 50%, rgba(53, 209, 177, 1) 75%,rgba(31, 163, 154, 1) 100%,  rgba(53, 209, 177, 1) 100%)', filter: "blur(225px)",mx:'auto',zIndex:-10}}>
   
                </Box>

              </motion.div>
              }
            </Box>
                {/* Hero Animation */}
                {/* <motion.div style={{zIndex:3}}
                     initial={{
                      x: 0,
                      y: '-60%',
                      scale:1
                    }}
                    animate={{
                      scale:slideDownAnimation ?1:0.9,
                      y:slideDownAnimation ?0:'-60%',
                    }}
                    transition={{
                      duration:0.3,
                      stiffness: 460,
                      damping: 20
                    }}
                    
                    > */}
                <Box mt={isMobile?-16:-32} mb={isMobile?2:4} sx={{position:'relative',backgroundColor:'white',opacity:'1',mx:'auto',width:{xs:'95%',md:'934px'},height:{xs:'100%',md:'516px'},maxHeight:'516px',maxWidth:'920px',zIndex:3,backgroundImage: `url(https://res.cloudinary.com/dp3hktn91/image/upload/v1690219457/Medical%20Match/Backgrounds/dots_ex6oyt.png)`,overflow:'hidden','&:focus':{outline:'none'},borderRadius:{xs:'12px',sm:'24px'},boxShadow:{xs:'4px 7px 4px 1px rgba(0, 0, 0, 0.3)',md: `12px 19px 14px 1px rgba(0, 0, 0, 0.3)`}}}>                    
                      <video  playsInline  autoPlay preload="none"  loop muted style={{position:'relative',width:'101%',height:'102%',zIndex:3}} >
                          <source src="https://res.cloudinary.com/dp3hktn91/video/upload/v1690285176/Medical%20Match/MM_vector_short_dvb91e.mp4" type="video/mp4"   />
                      </video>
                    
                </Box>
                {/* </motion.div> */}

        </Box>
    </>
  ) ;
};

export default Hero;
