import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Lander from "./Pages/Lander";
import { FormProvider } from "./Contexts/FormContext";
import content from "./content.json"
const App = () => {
  return (
    <>
    <FormProvider>
      <Router>     
        <Routes>
          <Route path="/" element={<Lander landerContent={content.lander} />} />
        </Routes>
      </Router>
    </FormProvider>
    </>
  );
};

export default App;
