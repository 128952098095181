
type clinicIconType = {
    fill?:string,
    width?:string,
    height?:string,
    
}

const ClinicianIcon = (props: clinicIconType) => {
    const{
        fill="#595D69",
        width="32",
         height="32"
    } = props
    
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path  clipRule="evenodd" d="M16.0002 4C14.1298 4 11.8419 4.68414 10.4908 5.15413C9.83237 5.3832 9.50211 6.09894 9.72024 6.76113L11.1521 11.108C10.8405 11.7859 10.6669 12.5402 10.6669 13.3333C10.6669 16.2789 13.0547 18.6667 16.0002 18.6667C18.9458 18.6667 21.3336 16.2789 21.3336 13.3333C21.3336 12.5402 21.16 11.7859 20.8483 11.1079L22.2802 6.76113C22.4984 6.09894 22.1681 5.3832 21.5096 5.15413C20.1586 4.68414 17.8706 4 16.0002 4ZM12.2535 12.2833C14.7579 11.2547 17.2426 11.2547 19.747 12.2833C19.7874 12.2999 19.8289 12.3123 19.8711 12.3207C19.9554 12.6437 20.0002 12.9831 20.0002 13.3333C20.0002 15.5425 18.2094 17.3333 16.0002 17.3333C13.7911 17.3333 12.0002 15.5425 12.0002 13.3333C12.0002 12.9831 12.0451 12.6437 12.1294 12.3207C12.1716 12.3123 12.2131 12.2999 12.2535 12.2833ZM16.6669 7.33333H18.0002V8.66667H16.6669V10H15.3336V8.66667H14.0002V7.33333H15.3336V6H16.6669V7.33333Z" />
<path  clipRule="evenodd" d="M15.2339 21.4619L11.2524 18.6665C11.2524 18.6665 4 20.9286 4 23.6444V28H28V23.6444C28 20.9286 20.7476 18.6665 20.7476 18.6665L16.7661 21.4619C16.3064 21.7847 15.6936 21.7847 15.2339 21.4619ZM21.3333 21.3332H22.6667V22.6665H24V23.9998H22.6667V25.3332H21.3333V23.9998H20V22.6665H21.3333V21.3332Z" />
</svg>

  )
}

export default ClinicianIcon