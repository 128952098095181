import React from 'react'

import Box from '@mui/material/Box';
import {Typography } from '@mui/material';

import { TypographyStyles } from '../Styles/TypographyStyles';
import { FormStyles } from '../Styles/FormStyles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import ClinicIcon from "../../Assets/Icons/ClinicIcon"
import ClinicianIcon from "../../Assets/Icons/ClinicianIcon"

import dots from "../../Assets/Backgrounds/dots.png"
import InfoCard from './InfoCard';



interface IInfoProps {
    infoContent:any,
    infoType:string,
    setInfoType:Function
}

const Info= (props:IInfoProps) => {
    const {
        infoType,
        infoContent,
        setInfoType
    }=props
    
    
    const handleInfoType = (
        event: React.MouseEvent<HTMLElement>,
        newInfoType: string,
      ) => {
        if (newInfoType !== null) {
            setInfoType(newInfoType);
        }
        console.log(infoType)
      };
  return (
    <>
        <Box sx={{width:'100%'}}>
            
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <ToggleButtonGroup
                    sx={{...FormStyles.toggleButtonGroupSx,zIndex:1}}
                    color="primary"
                    value={infoType}
                    exclusive
                    onChange={handleInfoType}
                    defaultValue="clinician"
                    // aria-label="Platform"
                    >
                    <ToggleButton value="clinician" sx={{...FormStyles.toggleButtonSx,display:'flex',justifyContent:'center',alignItems:'center',padding:'10px 15px 10px 15px',fontColor:'black'}}>
                        <ClinicianIcon fill={`${infoType==="clinician"?'#004DFE':'text.primary'}`} width="24"/>
                        <Typography mx={.2} sx={{...TypographyStyles.caption,color:`${infoType==="clinician"?'primary.main':'text.primary'}`,fontWeight:700,}}>
                            For Clinicians
                        </Typography>
                    </ToggleButton>

                    <ToggleButton value="healthFacility" sx={{...FormStyles.toggleButtonSx,display:'flex',justifyContent:'center',alignItems:'center',padding:'10px 15px 10px 15px'}}>
                        <ClinicIcon fill={`${infoType==="healthFacility"?'#004DFE':'text.primary'}`} width="24"/>
                        <Typography mx={.2} sx={{...TypographyStyles.caption,color:`${infoType==="healthFacility"?'primary.main':'text.primary'}`,fontWeight:700}}>
                            For Employers
                        </Typography>
                    </ToggleButton>

                </ToggleButtonGroup>
            </Box>
            <Box position="relative" mt={-2} sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start',width:'100%',background:"rgba(250,250,250,1)",zIndex:2,backgroundImage: `url(https://res.cloudinary.com/dp3hktn91/image/upload/v1690219457/Medical%20Match/Backgrounds/dots_ex6oyt.png)`,}}>
                {/* <img src={dots} style={{width:'100%',height:'100%',zIndex:-2,border:'2px solid'}} /> */}
                
                {/* <Typography  my={2} sx={{width:'100%',...TypographyStyles.heading3,fontFamily:'Outfit',fontWeight:800,textAlign:'center',zIndex:3}}>
                {infoType==="healthFacility"?infoContent.infoOrganisations.title:infoContent.infoClinicians.title}
                </Typography> */}
            <Box mt={2} sx={{mx:'auto'}}>

                { infoType==="clinician" && infoContent.infoClinicians.content.map((data:any, index:number) => (
                    <InfoCard
                    key={data.key}
                    badgeTitle={data.badge}
                    contentTitle={data.title}
                    content={data.content}
                    image={data.image}
                    imageWidth={`${index===1?'45%':index===2?'70%':'50%'}`}
                    reverse={index % 2 === 1} // Alternate reverse prop for odd indices
                    />
                ))}
                { infoType==="healthFacility" && infoContent.infoOrganisations.content.map((data:any, index:number) => (
                    <InfoCard
                    key={data.key}
                    badgeTitle={data.badge}
                    contentTitle={data.title}
                    content={data.content}
                    image={data.image}
                    hideVectors={true}
                    reverse={index % 2 === 0} // Alternate reverse prop for odd indices
                    />
                ))}
            </Box>
            </Box>
                
        </Box>
    </>
  );
};

export default Info;
