import React, { useState, useRef } from "react";

import Hero from "../Components/Hero";
import Info from "../Components/Info/Info";
import Partners from "../Components/Partners";
import Stats from "../Components/Stats/Stats";
import JoinWaitlist from "../Components/JoinWaitlist";
import Faq from "../Components/Faq/Faq";

import Comparison from "../Components/Comparison";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

type LanderType = {
  landerContent: any;
};
const Lander = (props: LanderType) => {
  const { landerContent } = props;
  const [infoType, setInfoType] = useState("clinician");
  const waitlistRef = useRef<HTMLDivElement>(null);
  const scrollToWaitlist = () => {
    
    if (waitlistRef.current) {
      waitlistRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar scrollToWaitlist={scrollToWaitlist} />
      <Hero />
      <Info
        infoType={infoType}
        setInfoType={setInfoType}
        infoContent={landerContent.info}
      />
      <Partners />
      <Comparison />
      <Stats />
      <JoinWaitlist innerRef={waitlistRef} />
      <Faq
        setInfoType={setInfoType}
        infoType={infoType}
        faqContent={landerContent.faq}
      />
      <Footer />
    </>
  );
};

export default Lander;
