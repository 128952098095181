
import Box from '@mui/material/Box';
import {Typography } from '@mui/material';
import { TypographyStyles } from '../Styles/TypographyStyles';

type StatsCardType = {
    stats?:string,
    content?:string
}

const StatsCard =(props:StatsCardType) => {
    const {
        stats="123",
        content="Lorem ipsum dolor sit amet, consectetur adipiscing"
    } = props
  return(
    <>
        <Box m={2}  sx={{width:{xs:'200px',sm:'250px'},height:{xs:'200px',sm:'250px'},borderRadius:'30px',background:'rgba(230, 238, 255, 1)',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',zIndex:3}}>
            <Typography sx={{width:'100%',...TypographyStyles.heading2,fontFamily:'Outfit',fontWeight:300,textAlign:'center',color:'text.primary',zIndex:3}}>
                {stats}
            </Typography>
            <Typography sx={{width:'100%',...TypographyStyles.body2,fontFamily:'Outfit',fontWeight:400,textAlign:'center',color:'rgba(89, 93, 105, 1)',zIndex:3}}>
                {content}
            </Typography>
        </Box>
    </>
  ) ;
};

export default StatsCard;
