import React, { ReactNode, useState } from "react";

export const FormContext = React.createContext({
  userType: "",
  setUserType: (value: string) => {},
  userProfession: "",
  setUserProfession: (value: string) => {},
  userFacility:"",
  setUserFacility:(value:string)=>{},
  userState:"",
  setUserState:(value:string)=>{},
  recaptchaValue: null as string | null,
  setRecaptchaValue: (value: string | null) => {},
  captchaVisible: false,
  setCaptchaVisible: (value: boolean) => {},
  isLoading: false,
  setIsLoading: (value: boolean) => {},
  formData: {
    userName: "",
    userEmail: "",
    userType: "",
    userState:"",
    userProfession: "",
    userFacility:""
  },
  setFormData: (value: any) => {},
  isSubmitted: false,
  setIsSubmitted: (value: boolean) => {},
});

type FormProviderType = {
  children: ReactNode;
};

export const FormProvider = (props: FormProviderType) => {
  const [userType, setUserType] = useState("clinician");
  const [userProfession, setUserProfession] = useState("");
  const [userFacility, setUserFacility] = useState("");
  const [userState,setUserState]=useState("")
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const [captchaVisible, setCaptchaVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = React.useState({
    userName: "",
    userEmail: "",
    userState:userState,
    userType: userType,
    userProfession: userProfession,
    userFacility:userFacility
  });
  const [isSubmitted,setIsSubmitted]=useState(false)
  const { children } = props;

  return (
    <FormContext.Provider
      value={{
        userType,
        setUserType,
        userProfession,
        setUserProfession,
        userFacility,
        setUserFacility,
        userState,
        setUserState,
        recaptchaValue,
        setRecaptchaValue,
        captchaVisible,
        setCaptchaVisible,
        isLoading,
        setIsLoading,
        formData,
        setFormData,
        isSubmitted,
        setIsSubmitted,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
