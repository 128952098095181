import emailjs from 'emailjs-com';

export const sendEmail = async (formData: Record<string, unknown>) => {
  const serviceId = process.env.REACT_APP_SERVICE_ID || "" ;
  let templateId=""
  const userId = process.env.REACT_APP_USER_ID || "";
  
  if (formData.userType === "healthFacility") {
    delete formData.userProfession;
    templateId="template_healthFacility"
  }
  
  // Remove userFacility attribute if userType is "clinician"
  if (formData.userType === "clinician") {
    delete formData.userFacility;
    templateId="template_clinician"
  }
  
  
  try {
    const response = await emailjs.send(serviceId, templateId, formData, userId);
    console.log('Email sent successfully!', response);
    return response; // Return the response from the send function
  } catch (error) {
    console.error('Error sending email:', error);
    throw error // Rethrow the error to be caught by the calling function, if needed
  }
};
