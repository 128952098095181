
import Box from '@mui/material/Box';
import {Typography } from '@mui/material';

import { TypographyStyles } from './Styles/TypographyStyles';
import Form from "./Form"

type JoinWaitlistType={
    registrations?:number
    innerRef?:React.Ref<HTMLDivElement>;
}

const JoinWaitlist = (props:JoinWaitlistType) => {
    const{
        registrations=200,
        innerRef,
    }=props
  return(
    <>
        <Box ref={innerRef} mb={3} sx={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',width:'100%',}}>

            <Box sx={{width:{xs:'100%',md:'90%'},backgroundColor:'primary.dark',display:'flex',flexDirection:{xs:'column',md:'row'},justifyContent:'space-around',alignItems:'center',maxWidth:'1440px',mx:'auto',borderRadius:{xs:'20px',md:'60px'},overflow:'hidden',backgroundImage:`Url(https://res.cloudinary.com/dp3hktn91/image/upload/v1690219458/Medical%20Match/Backgrounds/waitlist_iuch1a.png)`,backgroundRepeat:'no-repeat',backgroundPosition:'bottom',backgroundPositionY:'90%'}}>
                {/* Join the waitlist */}
                <Box m={1} sx={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
                    <Typography 
                                sx={{...TypographyStyles.heading1,fontWeight:'700',lineHeight:'70px',fontFamily:'outfit',textAlign:{xs:'center',md:'left'},
                                background:'-webkit-linear-gradient(0deg, rgba(236, 249, 85, 1) 25%, rgba(124, 239, 103, 1) 50%, rgba(53, 209, 177, 1) 75%,rgba(31, 163, 154, 1) 100%,  rgba(53, 209, 177, 1) 100%)',WebkitBackgroundClip: "text",WebkitTextFillColor: "transparent"}}>
                                Join the waitlist
                    </Typography>
                    <Typography my={2} sx={{fontSize: { xs: "1.1rem", md: "1.35rem" },color:'text.secondary',fontWeight:500,zIndex:2,maxWidth:'512px',textAlign:{xs:'center',md:'left'}}}>
                                Get early access and be the first to know when we launch. this includes many benefits and promotions
                    </Typography> 
                    <Typography  sx={{...TypographyStyles.body1,textAlign:{xs:'center',md:'left'},color:'text.secondary',fontWeight:600,zIndex:2,maxWidth:'512px',background:'-webkit-linear-gradient(0deg, rgba(236, 249, 85, 1) 25%, rgba(124, 239, 103, 1) 50%, rgba(53, 209, 177, 1) 75%,rgba(31, 163, 154, 1) 100%,  rgba(53, 209, 177, 1) 100%)',WebkitBackgroundClip: "text",WebkitTextFillColor: "transparent"}}>
                                {registrations}+ People already joined!
                    </Typography> 
                </Box>
                <Box m={1}  p={4} sx={{backgroundColor:'text.secondary',transform:'translateY(40px)',borderRadius:{xs:'60px',md:'40px'},maxWidth:'428px',width:'100%'}}>
                    <Form/>
                </Box>
            </Box>
            <Box sx={{width:{xs:'90%',md:'80%'},backgroundColor:'primary.main',height:'30px',borderBottomLeftRadius:'60px',borderBottomRightRadius:'60px',maxWidth:'1320px'}}>

            </Box>
            <Box sx={{width:{xs:'75%',md:'70%'},maxWidth:'1200px',backgroundColor:'secondary.main',height:'20px',borderBottomLeftRadius:'60px',borderBottomRightRadius:'60px',}}>

            </Box>
        </Box>
    </>
  ) ;
};

export default JoinWaitlist;
